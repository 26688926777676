<template>
  <div class="home">
    <el-container>
      <el-aside :width="width">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          background-color="#000"
          text-color="#fff"
          :collapse="isCollapse"
          :default-active="$route.path"
          router
          active-text-color="#ffffff"
        >
          <template v-for="(item, index) in getTreeMenu">
            <el-submenu v-if="item.children.length" :key="item._id" :index="index + ''">
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{ item.meta.title }}</span>
              </template>
              <template v-for="itemT in item.children">
                <el-menu-item :index="itemT.path" :key="itemT._id">
                  <i :class="itemT.icon"></i>
                  <span>{{ itemT.meta.title }}</span>
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item v-else :index="item.path" :key="item._id">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <header-main
            :isCollapse="isCollapse"
            @collapseStatus="collapseStatus"
          ></header-main>
        </el-header>
        <!-- <work-tab></work-tab> -->
        <el-main>
          <div class="cp-content">
            <keep-alive :exclude="closingPage">
              <router-view></router-view>
            </keep-alive>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import WorkTab from "../components/WorkTab.vue";
import HeaderMain from "../components/HeaderMain.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    WorkTab,
    HeaderMain,
  },
  data() {
    return {
      isCollapse: false,
    };
  },
  methods: {
    collapseStatus() {
      this.isCollapse = !this.isCollapse;
    },
  },
  computed: {
    width() {
      return !this.isCollapse ? "200px" : "64px";
    },
    closingPage() {
      return this.$store.state.closingPage;
    },
    ...mapGetters(["getTreeMenu"]),
  }
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 100%;
}

.el-container {
  height: 100%;
}

.el-aside {
  background-color: #000;
  transition: all 0.3s;
}

.el-menu {
  height: 100%;
  border-right: none;
  transition: all 0.3s;
}

.home:deep() .el-menu-item.is-active {
  background-color: #4d70ff !important;
}

.el-header {
  border-bottom: 1px solid #e4e7ed;
}

.el-main {
  background-color: #eee;
}

.workTab:deep() .el-tabs__header {
  margin: 0;
}
</style>
