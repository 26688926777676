export function translateDataToTree(data) {
    let parents = data.filter(value => !value.parentId);
    let children = data.filter(value => value.parentId);
    let translater = (parents, children) => {
        parents.forEach(e => {
            e.children = [];
            children.forEach((current, index) => {
                if (String(current.parentId) === String(e._id)) {
                    let temp = JSON.parse(JSON.stringify(children));
                    temp.slice(index, 1);
                    translater([current], temp);
                    typeof e.children !== undefined ? (e.children).push(current) : (e.children) = [current]
                }
            })
        })
    }
    translater(parents, children);
    return parents;
}