<template>
  <div class="workTab">
    <el-tabs
      v-model="activeTab"
      @tab-remove="removeTab"
      @tab-click="clickTab"
    >
      <el-tab-pane
        v-for="t in worktabs"
        :key="t.name"
        :label="t.tabname"
        :name="t.name"
        :closable="t.closable"
      >
        <span slot="label">
            <i class="icon iconfont icon-dot"></i>
            {{ t.tabname }}
        </span>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: "homepage"
    };
  },
  methods: {
    clickTab(tab) {
      let path = this.worktabs[1 * tab.index].path;
      if(path !== this.$route.path){
        this.$router.push(path);
      }
    },
    removeTab(name) {
      this.$store.dispatch("worktabRemove", name);
    },
  },
  computed: {
    worktabs() {
      return this.$store.state.worktab.list;
    }
  },
  watch: {
    "$store.state.worktab.current"(tab) {
      this.activeTab = tab.name;
    },
  },
  created() {
    setTimeout(() => {
      this.activeTab = this.$store.state.worktab.current.name;
    }, 500);
  },
};
</script>

<style>
.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 20px !important;
}
</style>