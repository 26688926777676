<template>
  <div class="login-container">
    <div class="login">
      <div class="login_left">
        <img src="../assets/login_left.png" alt="" />
      </div>
      <div class="login_right">
        <el-form :model="model" :rules="rules" ref="ruleForm">
          <el-form-item>
            <div class="login_title">系统登录</div>
          </el-form-item>
          <el-form-item class="login_item" prop="username">
            <el-input
              prefix-icon="el-icon-user"
              clearable
              placeholder="请输入用户名"
              v-model.trim="model.username"
            ></el-input>
          </el-form-item>
          <el-form-item class="login_item" prop="password">
            <el-input
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
              clearable
              v-model.trim="model.password"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item class="login_item" prop="verifyCode">
            <div class="verify">
              <el-input
                placeholder="请输入验证码"
                clearable
                v-model.trim="model.verifyCode"
              ></el-input>
              <div id="v_container" @click="resetV"></div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              class="submitBtn"
              type="primary"
              @click="submit('ruleForm')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { loginFunction, getTreeMenuFunction } from "../api/user";
import { GVerify } from "../utils/verifyCode";
import { erf } from "mathjs";
export default {
  data() {
    return {
      model: {
        username: "admin",
        password: "123456",
        verifyCode: "",
      },
      verifyCode: "",
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        verifyCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    async submit(formName) {
      var that = this;

      // 获取验证码
      var verifyCode = this.model.verifyCode;
      var verifyFlag = this.verifyCode.validate(verifyCode);
      if (!verifyFlag) {
        that.$notify.error({
          title: "系统提示",
          message: "验证码输入错误",
        });
        return;
      }

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 判断是否登录成功
          let param = {
            name: that.model.username,
            password: that.model.password,
          };
          const res = await loginFunction(param);
          if (res.message) {
            return that.$notify.error({
              title: "系统提示",
              message: res.message,
            });
          } else {
            that.$notify({
              title: "系统提示",
              message: "登录成功",
              type: "success",
              duration: 1000,
            });
            sessionStorage.setItem("userInfo", JSON.stringify(res.data));
            this.$router.replace("/home").catch(() => {});
          }
        } else {
          return false;
        }
      });
    },
    resetV(){
      this.verifyCode = new GVerify("v_container");
    }
  },
  mounted() {
    this.verifyCode = new GVerify("v_container");
  },
};
</script>

<style scoped>
.login-container {
  height: 100%;
  min-height: 550px;
  background-color: #eee;
  background-size: cover;
  background-image: url("../assets/login.svg");
  display: flex;
  align-items: center;
  justify-content: center;
}
.login {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 95%;
  height: 95%;
  padding: 0 50px;
  background-color: #fffc;
  border-radius: 10px;
}
.login_left {
  width: 50%;
  margin-right: 10px;
}
.login_left img {
  width: 100%;
  height: 100%;
}
.login_right {
  flex: 1;
}
.el-form {
  width: 50%;
  padding: 20px 40px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: #0000001a 0 2px 10px 2px;
}
.el-form:deep().login_title {
  color: #000;
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  width: 100%;
  margin-top: 22px;
}
.el-form:deep() .login_item .el-input__wrapper {
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #454545;
  padding: 10px;
  box-shadow: unset;
}
.el-form:deep() .login_item .el-input__wrapper .el-input__inner {
  color: #fff;
}
.el-form:deep() .submitBtn {
  width: 100%;
}
.verify {
  display: flex;
  align-items: center;
}
.verify #v_container {
  height: 40px;
  margin-left: 10px;
}
</style>
