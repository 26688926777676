import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index';
import { getTreeMenuFunction } from "../api/user";
import { translateDataToTree } from "../utils/translateTree";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    worktab: {
      list: [
        {
          name: 'homepage',
          tabname: '主页',
          path: '/home/page',
          closable: false
        }
      ],
      current: {
        name: 'homepage',
        tabname: '主页',
        path: '/home/page',
        closable: false
      }
    },
    closingPage: '',
    userInfo: {},
    token: "",
    treeMenu: [],
    routeList: [],
  },
  getters: {
    getToken(state) {
      return state.token || sessionStorage.token || ""
    },
    getUserInfo(state) {
      return state.userInfo || JSON.parse(sessionStorage.userInfo)
    },
    getAvatar(state){
      return JSON.parse(sessionStorage.userInfo).avatar[0]
    },
    getNickName(state) {
      return state.userInfo.nickname || sessionStorage.nickname || ""
    },
    getTreeMenu(state) {
      return state.routeList.length ? state.routeList : translateDataToTree(JSON.parse(sessionStorage.userInfo).route)
    },
    getRouteList(state) {
      return state.routeList.length ? state.routeList : JSON.parse(sessionStorage.routeList)
    }
  },
  mutations: {
    worktabRemove(state, p) {
      let end = state.worktab.list.findIndex(s => s.name === p);
      if (end > -1) {
        state.closingPage = state.worktab.list[end].name
        state.worktab.list.splice(end, 1);
      }
      //返回到上一个标签
      if (p === state.worktab.current.name) {
        let len = state.worktab.list.length;
        router.push(state.worktab.list[len - 1].path)
      }
    },
    worktabRoute(state, p) {
      let end = state.worktab.list.findIndex(s => s.name === p.to.name);
      if (end > -1) {
        state.worktab.current = state.worktab.list[end];
      } else {
        if (p.to.name !== "login") {
          state.worktab.list.push(p.to);
          state.worktab.current = p.to;
        }
      }
      state.closingPage = ''
    },
    setToken(state, token) {
      state.token = token;
      sessionStorage.setItem('token', token);
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      sessionStorage.setItem('nickname', userInfo.nickname);
      sessionStorage.setItem('_id', userInfo._id);
      sessionStorage.setItem('avatar', userInfo.avatar[0])
    },
    async getTreeMenuF(state, id) {
      const res = await getTreeMenuFunction(id);
      state.treeMenu = res.menu;
      state.routeList = res.route;
      sessionStorage.setItem('treeMenu', JSON.stringify(res.menu));
      sessionStorage.setItem('routeList', JSON.stringify(res.route))
    }
  },
  actions: {
    worktabRemove({ commit }, p) {
      commit('worktabRemove', p);
    },
    worktabRoute({ commit }, p) {
      commit('worktabRoute', p);
    },
    getTreeMenuF({ commit }, p) {
      commit('getTreeMenuF', p)
    }
  },
  modules: {
  }
})
