import request from "../http/index";

export function createUserFunction(params){
    return request({
        url: 'user/create',
        method: 'post',
        data: params
    })
}

export function deleteUserFunction(id){
    return request({
        url: `user/delete/${id}`,
        method: 'delete'
    })
}

export function updateUserFunction(id, params){
    return request({
        url: `user/update/${id}`,
        method: 'put',
        data: params
    })
}

export function userListFunction(params){
    return request({
        url: 'other/user/list',
        method: 'get',
        params
    })
}

//用户登录
export function loginFunction(params){
    return request({
        url: 'other/login',
        method: 'post',
        data: params
    })
}

//返回菜单
export function getTreeMenuFunction(id){
    return request({
        url: `other/menu/list/${id}`,
        method: 'get'
    })
}
