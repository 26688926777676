<template>
  <div class="header-main">
    <div class="collapse">
      <i
        v-if="!isCollapse"
        @click="changeCollapse()"
        class="el-icon-d-arrow-left"
      ></i>
      <i v-else @click="changeCollapse()" class="el-icon-d-arrow-right"></i>
    </div>
    <div class="header-right">
      <div class="refresh header-item" @click="refresh">
        <i class="el-icon-refresh-right"></i>
      </div>
      <div class="screen header-item" @click="screen">
        <i v-if="!fullScreen" class="icon iconfont icon-quanping"></i>
        <i v-else class="icon iconfont icon-tuichuquanping"></i>
      </div>
      <div class="header-item avater">
        <el-dropdown trigger="click">
          <img :src="userInfo.avatar[0]" alt="" />
          <el-dropdown-menu slot="dropdown" size="small">
            <el-dropdown-item>
              <div @click="logout">
                <i class="el-icon-switch-button"></i>
                退出登录
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="header-item username">
        <span>{{ userInfo.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["collapseStatus"],
  data() {
    return {
      fullScreen: false,
      userInfo: {
        avatar: []
      }
    };
  },
  methods: {
    changeCollapse() {
      this.$emit("collapseStatus");
    },
    refresh() {
      this.$router.go(0);
    },
    screen() {
      let element = document.documentElement;
      if (this.fullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullScreen = !this.fullScreen;
    },
    logout(){
      console.log(1);
      sessionStorage.clear();
      localStorage.clear();
      this.$router.replace('/login')
    }
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.userInfo);
  },
  // computed: {
  //   ...mapGetters(["getUserInfo"]),
  // },
};
</script>

<style>
.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.collapse {
  cursor: pointer;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-item {
  margin-right: 20px;
  cursor: pointer;
  position: relative;
}

.header-item:last-child {
  margin-right: 0;
}

.avater {
  width: 30px;
  height: 30px;
}

.avater img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
</style>