import router from './index';
import Layout from "../views/HomeView.vue"
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';

nProgress.configure({ showSpinner: false })


const filterRoutes = ["/", "/login"];
router.beforeEach((to, from, next) => {
    nProgress.start();
    document.title = to.meta.title;
    if (filterRoutes.indexOf(to.path) !== -1) {
        next();
        return false;
    }
    if (router.options.routes.length == 2) {
        const userInfo = JSON.parse(sessionStorage.userInfo) ?? [];
        let children = [];
        userInfo.route.forEach(e => {
            if(e.component !== 'Layout'){
                children.push(e)
            }
        })
        let route = [
            {
                path: '/home',
                name: 'home',
                component: "HomeView.vue",
                children
            }
        ]
        if (userInfo.token && userInfo.route) onFilterRoutes(to, next, route);
        else next({ path: '/login', replace: true })
    } else {
        next();
    }
});
router.afterEach(() => {
    nProgress.done();
})

function loadView(view) {
    return () => import(`@/views/${view}`);
}

async function onFilterRoutes(to, next, e) {
    const routes = await filterAsyncRoutes(e);
    routes.forEach(item => {
        router.options.routes.push(item);
        router.addRoute(item)
    });
    next(to.path)
}

function filterAsyncRoutes(data) {
    const routes = data.filter(item => {
        item.component = loadView(item.component);
        if (item.children && item.children.length > 0) item.children = filterAsyncRoutes(item.children);
        return true
    })
    return routes
}