import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import nProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getTreeMenuFunction } from "../api/user";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: "登录"
    }
  }
]
// {
//   path: '/home/page',
//   name: 'homepage',
//   component: () => import('../views/HomePage/HomePage.vue'),
//   meta: {
//     title: "主页"
//   }
// },
// {
//   path: '/permission/list',
//   name: 'permission',
//   component: () => import('../views/Permission/PermissionView.vue'),
//   meta: {
//     title: "菜单管理"
//   }
// },
// {
//   path: '/role/list',
//   name: 'role',
//   component: () => import('../views/Role/RoleView.vue'),
//   meta: {
//     title: "角色管理"
//   }
// },
// {
//   path: '/user/list',
//   name: 'user',
//   component: () => import('../views/User/UserView.vue'),
//   meta: {
//     title: "用户管理"
//   }
// }

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}





const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
