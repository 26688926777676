import axios from "axios";
import nProgress from "nprogress";
import 'nprogress/nprogress.css';
import store from "../store/index";

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:4125/admin/api/' : 'https://questionback.qxrjkj.site/admin';
const request = axios.create({
    baseURL,
    timeout: 50000
});

request.interceptors.request.use(config => {
    const token = store.getters.getToken;
    if(token){
        config.headers.Authorization = `Bearer ${token}`;
    }
    nProgress.start();
    return config;
});

request.interceptors.response.use(res => {
    nProgress.done();
    return res.data
}, err => {
    return '服务器响应数据失败'
})

export default request;